import React from 'react';
import 'es6-promise/auto';
import axios from 'axios';
import createAuth0Client from '@auth0/auth0-spa-js';
import Application from './Application';


class App extends React.Component {

  constructor(props) {
    super(props);

    this.appStatus = {
      LOGGED_IN: 'LOGGED_IN',
      LOGGING_OUT: 'LOGGING_OUT',
      LOGGED_OUT: 'LOGGED_OUT',
      LOGOUT_ERROR: 'LOGOUT_ERROR',
    };

    this.state = {
      loading: true,
      shouldRenderLogoutFrames: false,
      activeClientId: null,
    };

    this.auth0 = null;

    this.handleApplicationLoggedOut = this.handleApplicationLoggedOut.bind(this);
    this.handleFrameLoaded = this.handleFrameLoaded.bind(this);
    this.renderApplications = this.renderApplications.bind(this);
    this.renderSuccessTextIfFinished = this.renderSuccessTextIfFinished.bind(this);
    this.renderLogoutFrame = this.renderLogoutFrame.bind(this);
  }

  async componentDidMount() {
    // Add the status to each application, preferably it should also be filtered towards a list
    // of applications the user is actually logged in to.
    // TODO: Figure out how to get the list of active sessions
    this.auth0 = await createAuth0Client({
      client_id: window.config.client_id,
      domain: window.config.domain,
    });

    const { data } = await axios.get('applications.json');
    const applicationsState = Object.keys(data).reduce((result, clientId) => {
      result[clientId] = {...data[clientId], status: this.appStatus.LOGGED_IN};
      return result;
    }, {});

    // Start by logging out of the first application in the list
    const firstClient = Object.keys(applicationsState)[0];
    applicationsState[firstClient].status = this.appStatus.LOGGING_OUT;

    this.setState(state => {
      state.loading = false;
      state.applications = applicationsState;
      state.shouldRenderLogoutFrames = true;
      state.activeClientId = firstClient;

      return state;
    });
  }

  handleApplicationLoggedOut(clientId) {
    const { applications } = this.state;

    // Find the next client that should be logged out
    const nextClientId = Object.keys(applications).filter(clientId =>
      applications[clientId].status === this.appStatus.LOGGED_IN)[0];

    this.setState(state => {
      state.applications[clientId].status = this.appStatus.LOGGED_OUT;

      if (nextClientId) {
        state.applications[nextClientId].status = this.appStatus.LOGGING_OUT;
        state.activeClientId = nextClientId;
      } else {
        this.auth0.logout({
          returnTo: window.redirectUrl,
        });
        state.activeClientId = null;
      }

      return state;
    });
  }

  handleFrameLoaded(frame, clientId) {
    // TODO: Preferably we should detect if it failed to logout of a application somehow, but that doesn't seem
    // to be possible using iframes originating at a different domain.
    const success = true;

    if (success) {
      this.handleApplicationLoggedOut(clientId, this.appStatus.LOGGED_OUT);
    } else {
      this.setState(state => {
        state.applications[clientId].status = this.appStatus.LOGOUT_ERROR;

        return state;
      });
    }
  }

  renderApplications() {
    const { applications } = this.state;

    return (
      Object.keys(applications).map(clientId => {
        return (<Application
          key={`app-${clientId}`}
          name={applications[clientId].name}
          status={applications[clientId].status}
        />)
      })
    );
  }

  renderSuccessTextIfFinished() {
    const { applications } = this.state;

    const complete = Object.keys(applications).filter(clientId =>
      applications[clientId].status === this.appStatus.LOGGED_IN
      || applications[clientId].status === this.appStatus.LOGGING_OUT)
      .length === 0;

    if (complete) {
      return (<p>Log out successful!</p>);
    }
  }

  renderLogoutFrame() {
    const { applications, activeClientId } = this.state;

    if (!activeClientId) {
      return null;
    }

    const status = applications[activeClientId].status;

    if (activeClientId && status === this.appStatus.LOGGING_OUT) {
      return (<iframe
        title={activeClientId}
        height={0}
        width={0}
        frameBorder={0}
        src={applications[activeClientId].logoutUrl}
        onLoad={(frame) => this.handleFrameLoaded(frame, activeClientId)}
      />)
    }
  }

  render() {
    const { shouldRenderLogoutFrames, loading } = this.state;

    if (loading) {
      return (<div>Loading applications..</div>);
    }

    return window.debugMode 
    ? (
      <div className="App">
        {this.renderApplications()}
        {this.renderSuccessTextIfFinished()}
        {shouldRenderLogoutFrames && this.renderLogoutFrame()}
      </div>
    )
    : (
      <div className="App">
        <img src={'./tribia.png'} alt={'Tribia'} />
        <p>Please wait while we log you out..</p>
        {this.renderSuccessTextIfFinished()}
        {shouldRenderLogoutFrames && this.renderLogoutFrame()}  
      </div>
    );
  }

}

export default App;
