import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleNotch, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import './Application.css';

function Application(props) {
  const statusIcons = {
    LOGGED_IN: faExclamationCircle,
    LOGGING_OUT: faCircleNotch,
    LOGGED_OUT: faCheckCircle,
  };

  const { status, name } = props;

  return (
    <div className={`applicationItem ${status}`}>{name} <FontAwesomeIcon icon={statusIcons[status]} spin={status === 'LOGGING_OUT'} /></div>
  );
}

export default Application;